.arg2-container{
    display: grid;
    grid-template-columns: repeat(4,1fr) ;
    width: 90%;
    margin: 0 auto;
    gap: 10px;
}
.arg2-container img{
    width: 100%;
    height: 100%;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.arg2-container img:nth-child(1){
    grid-column: 1/3;
}
.arg2-container img:nth-child(2){
    grid-column: 3/5;
}
.arg2-container img:nth-child(3){
    grid-column: 1/3;
}
.arg2-container img:nth-child(4){
    grid-column: 3/5;
}
.arg2-container img:nth-child(5){
    grid-column: 1/5;
}
.arg2-container img:nth-child(6){
    grid-column: 1/2;
}
.arg2-container img:nth-child(7){
    grid-column: 1/2;
}
.arg2-container img:nth-child(8){
    grid-column: 2/5;
    grid-row: 4/6;
    height: 620px;
}
@media (max-width: 750px) {
    .arg2-container img:nth-child(6){
        grid-column: 1/3;
        
    }
    .arg2-container img:nth-child(7){
        grid-column: 3/5;

    }
    .arg2-container img:nth-child(8){
        grid-column: 1/5;
        grid-row: 5/7;


    }


}