.footer-container{
    width: 80%;
    margin: 0 auto 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(217, 217, 217, 0.33);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 60px;
}
.footer-container h2{
    font-weight: 200;
    font-size: 48px;
    line-height: 58px;
    color: #262803;
}
.footer-separate{
    margin: 0 50px;
    width: 6px;
    height: 113px;
    border: #262803 solid 6px;
}
.footer-container a{
    text-decoration: none;
    color: black;
}
.footer-container a:hover{
    opacity: .5;
    cursor: pointer;
}

@media (max-width: 650px) {
    .footer-container{
        box-shadow: none;
        width: 100%;
        margin: 0;
    }
}
@media (max-width: 450px) {
    .footer-container{
        flex-direction: column;
    }
    .footer-container p{
        text-align: center;
    }
    .footer-separate{
        display: none;
    }
}