.arg1-title{
    width: 80%;
    margin: 0 auto;
    background: #262803;
    color: white;
    padding: 30px 86px;
    font-size: 28px;
    text-align: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-top: -80px;
}
.arg1-contents{
    margin: 0 auto;
    width: 70%;
    background: rgba(217, 217, 217, 0.33);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 50px;
    display: flex;
    align-items: start;
    justify-content: space-between;

}
.arg1-options{
    width: 80%;
    border-left: #262803 solid 6px; 
    padding-left: 10px;
}
.arg1-contents h2{
    font-weight: 200;
    font-size: 48px;
    line-height: 58px;
    color: #262803;
    margin-bottom: 20px;
}
.arg1-contents h3{
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #262803;
    margin-bottom: 0;
}
.arg1-contents p{
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: #262803;
}
.arg1-price{
    width: 259px;    
    margin: -35px auto 0;
}

.arg1-price h2{
    padding: 8px 70px;
    font-weight: 300;
    font-size: 32px;
    line-height: 39px;
    color: #fff;
    background: #646559;
}
.arg1-price p{
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #262803;

}
@media (max-width: 820px){
    .arg1-contents{
        width: 100%;
    }
    .arg1-title{
        margin-top: -5px;
        width: 100%;
        padding: 50px 0;
    }
}
@media (max-width: 650px){
    .arg1-contents{
        flex-direction: column;
    }
}
@media (max-width: 550px) {
    .arg1-title h2{
        width: 90%;
        margin: 0 auto;
        font-size: 20px;
    }
}
@media (max-width: 400px) {
    .arg1-contents{
        padding: 20px;
        margin: 40px 0px;
    }
    .arg1-contents h2{
        font-size: 35px;
        line-height: 40px;
    }
    .arg1-options{
        width: 100%;
    }
}