.arg3-container{
    width: 100%;
    margin: 100px auto;
    background: #262803;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0;
}
.arg3-container img{
    width: 90%;
    margin: 10px 0;
}

@media (max-width: 650px) {
    .arg3-container{
        margin-bottom: 0;
    }
}