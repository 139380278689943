.argh-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
}
.argh-trips{
    align-self: flex-start;
}
.argh-countries{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.argh-title{
    font-size: 93px;
    line-height: 116px;
}
.countries-header{
    width: 75%;
}
.countries-header:hover{
    animation: hoverHeader 2s;
}

@media (max-width: 800px) {
    .argh-container{
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: flex-end;
    }
   
    .argh-title{
        margin-top: 100px;
    }
    .argh-countries{
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-start;
        height: 20%;
    }
    .argh-countries img{
        width: 25%;
        margin-right: -20px;
    }
    .argh-countries img:last-child{
        margin-right: 0;
    }
}
@media (max-width: 550px){
    .argh-container{
        height: 70vh;
    }
    .argh-title{
        border: none !important;
        padding: 0 !important;
        width: 100%;
    }
    .argh-title p{
        font-size: 70px;
        line-height: 80px;
        text-align: center;
        padding: 0 !important;
    }
}
@media (max-width: 350px){
    .argh-title p{
        font-size: 50px;
        line-height: 60px;

    }
}

@keyframes hoverHeader {
    0%{
        transform: scale(1) translateX(0px) translateY(0px);

    }50%{
        transform: scale(1.1) translateX(-15px) translateY(-5px);
    }
}